var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dishes"},[(_vm.customHeadings && _vm.customHeadings.dishes.name)?_c('v-card-title',{staticClass:"headline font-weight-black pl-0 pb-0"},[_vm._v(" "+_vm._s(_vm.customHeadings.dishes.name)+" ")]):_vm._e(),(_vm.customHeadings && _vm.customHeadings.dishes.description)?_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.customHeadings.dishes.description))]):_vm._e(),_vm._l((_vm.data),function(category){return _c('div',{key:category._id},[(category.items.length !== 0)?_c('div',{staticClass:"category",attrs:{"id":category._id}},[_c('v-card-title',{staticClass:"headline font-weight-black pl-0 pb-0"},[_vm._v(" "+_vm._s(category.name)+" ")]),(category.description)?_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(category.description))]):_vm._e(),_c('v-expansion-panels',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'justify-start' : null,attrs:{"accordion":""}},_vm._l((category.items),function(dish,i){return _c('v-expansion-panel',{key:i,style:(_vm.$vuetify.breakpoint.lgAndUp
              ? 'max-width: 50%; border-color: transparent'
              : null),attrs:{"active-class":"active","disabled":(!dish.allowedDeliveryMethods.takeaway &&
              !dish.allowedDeliveryMethods.delivery &&
              !dish.allowedDeliveryMethods.inplace) ||
            !_vm.isSelectedDeliveryMethodAllowed(dish)}},[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('v-col',{staticClass:"hidden-xs-only image-thumbnail",attrs:{"cols":"1","sm":"3","lg":"3"}},[(dish.imageUrl)?_c('v-img',{staticClass:"mr-auto",staticStyle:{"border-radius":"8px"},attrs:{"height":"125","contain":_vm.imageContain === 'true',"src":dish.imageUrl}}):_c('v-img',{staticClass:"mr-auto",staticStyle:{"border-radius":"8px"},attrs:{"height":"125","width":"100%","src":_vm.imagePlaceholderUrl || 'https://embeddable.piknik.cc/img/demo/placeholder.gif'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-card-title',{staticStyle:{"padding-left":"0px","padding-top":"0px"}},[(_vm.order.findIndex(function (item) { return item._id === dish._id; }) > -1)?_c('span',{staticClass:"primary--text",staticStyle:{"margin-right":"8px"}},[_vm._v(_vm._s(_vm.order .filter(function (item) { return item._id === dish._id; }) .reduce( function (accumulator, dish) { return accumulator + dish.quantity; }, 0 ))+"x")]):_vm._e(),_vm._v(_vm._s(dish.name))]),_c('v-card-subtitle',{staticClass:"pl-0 pb-2"},[_c('span',{staticClass:"full-description"},[_vm._v(_vm._s(dish.description)+" "),(dish.allergens.length > 0)?_c('v-card-text',{staticStyle:{"padding":"0px"}},[_vm._v(" "+_vm._s(_vm.$t("allergens"))+": "+_vm._s(dish.allergens.join(", "))+" ")]):_vm._e()],1),_c('v-list-item-subtitle',{staticClass:"short-description"},[_vm._v(_vm._s(dish.description)+" ")])],1),_c('v-card-text',{staticStyle:{"padding-left":"0px","padding-bottom":"0px"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"padding-bottom":"0px"}},[_vm._v(_vm._s(_vm.calculateDishPrice(dish).toFixed(2))+_vm._s(_vm.currencySymbol))]),_vm._v(" /"+_vm._s(dish.amount)+" "),(_vm.mandatoryOptionsProvided(dish))?_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary","small":"","disabled":(!_vm.isOpen && !_vm.allowPreorderingWhenClosed) ||
                    (!dish.allowedDeliveryMethods.takeaway &&
                      !dish.allowedDeliveryMethods.delivery &&
                      !dish.allowedDeliveryMethods.inplace) ||
                    !_vm.mandatoryOptionsProvided(dish) ||
                    !_vm.isSelectedDeliveryMethodAllowed(dish)},nativeOn:{"click":function($event){$event.stopPropagation();_vm.$emit('add-dish', {
                      dish: dish,
                      options: _vm.selectedOptions[dish._id].filter(
                        function (e) { return dish.options.indexOf(e._id) !== -1; }
                      ),
                      price: _vm.calculateDishPrice(dish),
                    });
                    _vm.resetOptions(dish);}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")]):_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary","small":"","disabled":(!_vm.isOpen && !_vm.allowPreorderingWhenClosed) ||
                    (!dish.allowedDeliveryMethods.takeaway &&
                      !dish.allowedDeliveryMethods.delivery &&
                      !dish.allowedDeliveryMethods.inplace) ||
                    !_vm.isSelectedDeliveryMethodAllowed(dish)}},[_vm._v(" "+_vm._s(_vm.$t("selectOptions"))+" ")]),_c('div',[_c('AllowedDeliveryMethods',{attrs:{"allowedDeliveryMethods":dish.allowedDeliveryMethods}})],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-col',{staticStyle:{"padding-top":"0px"},attrs:{"cols":"12"}},[(dish.imageUrl && dish.options.length !== 0)?_c('v-img',{staticClass:"d-sm-none mb-4",staticStyle:{"border-radius":"8px"},attrs:{"max-height":"250","contain":_vm.imageContain === 'true',"src":dish.imageUrl}}):(dish.imageUrl)?_c('v-img',{staticClass:"d-sm-none",staticStyle:{"border-radius":"8px"},attrs:{"max-height":"250","contain":_vm.imageContain === 'true',"src":dish.imageUrl}}):_vm._e(),_c('v-card-text',{staticClass:"hidden-xs-only",staticStyle:{"padding":"0px"}},[_vm._v(" "+_vm._s(dish.description)+" ")]),(dish.allergens.length > 0)?_c('v-card-text',{staticClass:"hidden-xs-only",staticStyle:{"padding":"0px"}},[_vm._v(" "+_vm._s(_vm.$t("allergens"))+": "+_vm._s(dish.allergens.join(", "))+" ")]):_vm._e(),(dish.options.length !== 0)?_c('Options',{staticClass:"options",attrs:{"currencySymbol":_vm.currencySymbol,"dish":dish,"options":_vm.options,"selectedOptions":_vm.selectedOptions[dish._id],"expandFirstOption":_vm.expandFirstOption},on:{"add-option":function($event){return _vm.addOption($event, dish)},"remove-option":function($event){return _vm.removeOption($event, dish)}}}):_vm._e()],1)],1)],1)}),1)],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }