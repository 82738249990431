export default {
    categories: [
        {
            _id: "5fc11dd67096da4a38302fbb",
            name: "Polievky",
            rank: 1,
            color: "#FFC10085"
        },
        {
            _id: "5fc11e097096da4a38302fbe",
            name: "Hlavné jedlá",
            rank: 2,
            color: "#00FFEA9D"
        },
        {
            _id: "5fc11e1d7096da4a38302fbf",
            name: "Pizza",
            rank: 3,
            color: "#3CFF0073"
        },
        {
            _id: "5fc11e5e7096da4a38302fc3",
            name: "Prílohy",
            rank: 5,
            color: "#FF390087"
        },
        {
            _id: "5fc11e867096da4a38302fc4",
            name: "Nápoje",
            rank: 6,
            color: "#4A00FF55"
        },
        {
            _id: "5fd38c2522dfbec46bf5a8e0",
            name: "Burgre",
            rank: 4,
            color: "#FF00689E"
        }
    ],
    dailyMenu: [
        {
            synonyms: [],
            options: [],
            _id: '5fc3864a47c6af4072959d03',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (0 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '101',
            name: 'Krémová cesnaková polievka',
            category: '',
            description: 'krémová cesnaková polievka, krutóny',
            amount: '330ml',
            price: 0,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc386eb47c6af4072959d04',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (0 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '102',
            name: 'Zeleninový šalát s grilovaným hermelínom',
            category: '',
            description: 'listová zelenina, grilovaný hermelín',
            amount: '100g + 150g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3875a47c6af4072959d05',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (0 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '103',
            name: 'Kurací steak s ryžou ',
            category: '',
            description: 'kurací steak, ryža',
            amount: '150g + 200g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3880c47c6af4072959d06',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '104',
            name: 'Hovädzí vývar',
            category: '',
            description: 'hovädzí vývar s rezancami',
            amount: '330ml',
            price: 0,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc38d3c47c6af4072959d07',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '105',
            name: 'Špenátové rizoto',
            category: '',
            description: 'špenátové rizoto, strúhaný parmezán',
            amount: '200g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc38d8447c6af4072959d08',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '106',
            name: 'Palacinky s lekvárom',
            category: '',
            description: 'palacinky s jahodovým lekvárom, ovocie, šľahačka',
            amount: '200g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc38db047c6af4072959d09',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '107',
            name: 'Paradajková polievka',
            category: '',
            description: 'paradajková polievka, strúhaný parmezán',
            amount: '330ml',
            price: 0,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b59747c6af4072959d0a',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '108',
            name: 'Srnčí guláš na červenom víne s knedľou',
            category: '',
            description: 'srnčí guláš na červenom víne, knedľa',
            amount: '150g + 150g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b5dc47c6af4072959d0b',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '109',
            name: 'Špagety Bolognaise',
            category: '',
            description: 'špagety bolognaise, strúhaný syr',
            amount: '250g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b5fc47c6af4072959d0c',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '110',
            name: 'Hrášková polievka',
            category: '',
            description: 'hrášková polievka',
            amount: '330ml',
            price: 0,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b67f47c6af4072959d0e',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '111',
            name: 'Bravčový rezeň so zemiakovým pyré',
            category: '',
            description: 'bravčový rezeň, zemiakové pyré',
            amount: '130g + 150g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b6c947c6af4072959d0f',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '112',
            name: 'Parené buchty s kakaom a maslom',
            category: '',
            description: 'parené buchty s kakaom a maslom',
            amount: '250g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b6eb47c6af4072959d10',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (4 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '113',
            name: 'Slepačí vývar',
            category: '',
            description: 'slepačí vývar s rezancami',
            amount: '330ml',
            price: 0,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b72f47c6af4072959d11',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (4 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '114',
            name: 'Bryndzové halušky so slaninkou',
            category: '',
            description: 'bryndzové halušky, opekaná slaninka',
            amount: '250g',
            price: 5,
            imageUrl: ''
        },
        {
            synonyms: [],
            options: [],
            _id: '5fc3b77e47c6af4072959d12',
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            date: new Date(new Date().getTime() + (4 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
            id: '115',
            name: 'Zeleninový šalát s lososom',
            category: '',
            description: 'listová zelenina, losos s bylinkami',
            amount: '100g + 150g',
            price: 5,
            imageUrl: ''
        }
    ],
    dishes: [
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: ["1. Obilniny"],
            options: [],
            imageUrl: "https://embeddable.piknik.cc/img/demo/soup1.jpg",
            _id: "5fc11f337096da4a38302fc5",
            id: "1",
            name: "Slepačí vývar",
            category: "5fc11dd67096da4a38302fbb",
            description: "Čerstný slepačí vývar s mäsom, zeleninou a rezancami",
            amount: "330ml",
            price: 2.95
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [
                "5fd8e621b478005114e8f3a3"
            ],
            imageUrl: "https://embeddable.piknik.cc/img/demo/soup2.jpg",
            _id: "5fc11fac7096da4a38302fc6",
            id: "2",
            name: "Hokaido",
            category: "5fc11dd67096da4a38302fbb",
            description: "Hokaido polievka",
            amount: "330ml",
            price: 2.95
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [
                "5fd8e621b478005114e8f3a3"
            ],
            imageUrl: "https://embeddable.piknik.cc/img/demo/salad.jpg",
            _id: "5fc120037096da4a38302fc7",
            id: "3",
            name: "Zeleninový šalát s kuracím mäsom",
            category: "5fc11e097096da4a38302fbe",
            description: "Zeleninový šalát s kuracím mäsom",
            amount: "200g + 150g",
            price: 6.95
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [],
            _id: "5fc120ad7096da4a38302fcb",
            id: "4",
            name: "Hawai",
            category: "5fc11e1d7096da4a38302fbf",
            description: "Chrumkavá pizza priamo z pece",
            amount: "550g",
            price: 4.95,
            imageUrl: "https://embeddable.piknik.cc/img/demo/pizza1.jpg"
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [],
            _id: "5fc120eb7096da4a38302fcc",
            id: "5",
            name: "Kukuricová",
            category: "5fc11e1d7096da4a38302fbf",
            description: "Chrumkavá pizza priamo z pece",
            amount: "575g",
            price: 5.45,
            imageUrl: "https://embeddable.piknik.cc/img/demo/pizza2.jpg"
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [],
            _id: "5fc121387096da4a38302fcd",
            id: "6",
            name: "4 druhy syra",
            category: "5fc11e1d7096da4a38302fbf",
            description: "Chrumkavá pizza priamo z pece",
            amount: "600g",
            price: 6.15,
            imageUrl: "https://embeddable.piknik.cc/img/demo/pizza3.jpg"
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [],
            _id: "5fc122277096da4a38302fce",
            id: "7",
            name: "Hranolky",
            category: "5fc11e5e7096da4a38302fc3",
            description: "Hranolky",
            amount: "150g",
            price: 1.95,
            imageUrl: "https://embeddable.piknik.cc/img/demo/fries.jpg"
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: [],
            options: [],
            imageUrl: "https://embeddable.piknik.cc/img/demo/placeholder.gif",
            _id: "5fc122607096da4a38302fcf",
            id: "8",
            name: "Citronáda",
            category: "5fc11e867096da4a38302fc4",
            description: "Voda, mäta, citrón",
            amount: "500ml",
            price: 0.9
        },
        {
            status: true,
            allowedDeliveryMethods: { delivery: true, takeaway: true, inplace: true },
            synonyms: [],
            allergens: ["1. Obilniny", "7. Mlieko"],
            options: [
                "5fdf489b907ef65c0c488e5d",
                "5fd8e621b478005114e8f3a3"
            ],
            imageUrl: "",
            _id: "5fd38c9922dfbec46bf5a8e1",
            id: "9",
            name: "Klasický hovädzí burger",
            category: "5fd38c2522dfbec46bf5a8e0",
            description: "žemla, mäso, syr čedar",
            amount: "250g",
            price: 8.9
        }
    ],
    options: [
        {
            values: [
                {
                    _id: "5fd8e621b478005114e8f3a4",
                    name: "Batátové hranolky",
                    price: 2,
                    maximumAmount: 2
                },
                {
                    _id: "5fd8e6b8b478005114e8f3a7",
                    name: "Zelenina",
                    price: 1.5,
                    maximumAmount: 2
                },
                {
                    _id: "5fe104b6907ef65c0c488e6a",
                    name: "Ryža",
                    price: 1,
                    maximumAmount: 1
                }
            ],
            _id: "5fd8e621b478005114e8f3a3",
            name: "Príloha na výber",
            minimumSelections: 0,
            maximumSelections: 2
        },
        {
            values: [
                {
                    _id: "5fdf489b907ef65c0c488e60",
                    name: "Bezlepková",
                    price: 1.5,
                    maximumAmount: 1
                },
                {
                    _id: "5fdf489b907ef65c0c488e5f",
                    name: "Celozrnná",
                    price: 0,
                    maximumAmount: 1
                },
                {
                    _id: "5fdf489b907ef65c0c488e5e",
                    name: "Biela",
                    price: 0,
                    maximumAmount: 1
                }
            ],
            _id: "5fdf489b907ef65c0c488e5d",
            name: "Druh žemle",
            minimumSelections: 1,
            maximumSelections: 1
        }
    ],
    isOpen: false,
    dailyMenuOrderingTime: {
        from: '9:30',
        to: '15:30'
    },
    minimalOrderValue: 10,
}