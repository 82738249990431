var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dailyMenu",attrs:{"id":"dailyMenu"}},[_c('v-card-title',{staticClass:"headline font-weight-black pl-0 pb-0"},[_vm._v(" "+_vm._s(_vm.customHeadings && _vm.customHeadings.dailyMenu.name ? _vm.customHeadings.dailyMenu.name : _vm.$t("dailyMenu"))+" ")]),(_vm.customHeadings && _vm.customHeadings.dailyMenu.description)?_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.customHeadings.dailyMenu.description))]):_vm._e(),_c('v-expansion-panels',{attrs:{"accordion":"","value":_vm.data[0].disabled && _vm.data[1] && !_vm.data[1].disabled
        ? 1
        : _vm.data[0].disabled
        ? -1
        : 0}},_vm._l((_vm.data),function(date,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_c('div',[(
              new Date().setHours(0, 0, 0, 0) ===
              new Date(date.date).setHours(0, 0, 0, 0)
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"8px"},attrs:{"x-small":"","color":date.disabled ? '#fc010e' : '#13e46d'}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.dailyMenuOrderingTimeInfo)+" ")])]):(!date.disabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"8px"},attrs:{"x-small":"","color":"#ff9f1a"}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("acceptingPreOrders"))+" ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.getWeekdayName((new Date(date.date).getDay() || 7) - 1))+" ("+_vm._s(new Date(date.date).toLocaleDateString().replace(/ /g, ""))+") ")],1)]),_c('v-expansion-panel-content',{staticClass:"hello"},[_c('v-expansion-panels',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'justify-start' : null,attrs:{"accordion":""}},_vm._l((date.items),function(dish,i){return _c('v-expansion-panel',{key:i,style:(_vm.$vuetify.breakpoint.lgAndUp
                ? 'max-width: 50%; border-color: transparent'
                : null),attrs:{"active-class":"active","disabled":date.disabled ||
              (!dish.allowedDeliveryMethods.takeaway &&
                !dish.allowedDeliveryMethods.delivery &&
                !dish.allowedDeliveryMethods.inplace) ||
              !_vm.isSelectedDeliveryMethodAllowed(dish),"readonly":dish.options.length === 0}},[_c('v-expansion-panel-header',{staticClass:"py-0",attrs:{"hide-actions":""}},[(dish.imageUrl)?_c('v-col',{staticClass:"hidden-xs-only image-thumbnail",attrs:{"cols":"1","sm":"3","lg":"3"}},[_c('v-img',{staticClass:"mr-auto",staticStyle:{"border-radius":"8px"},attrs:{"height":"125","contain":_vm.imageContain === 'true',"src":dish.imageUrl}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":dish.imageUrl ? 9 : 12}},[_c('v-card-title',{staticStyle:{"padding-left":"0px","padding-top":"0px"}},[(
                      _vm.order.findIndex(function (item) { return item._id === dish._id; }) > -1
                    )?_c('span',{staticClass:"primary--text",staticStyle:{"margin-right":"8px"}},[_vm._v(_vm._s(_vm.order .filter(function (item) { return item._id === dish._id; }) .reduce( function (accumulator, dish) { return accumulator + dish.quantity; }, 0 ))+"x")]):_vm._e(),_vm._v(_vm._s(dish.name))]),_c('v-card-subtitle',{staticStyle:{"padding-left":"0px"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({},'v-list-item-subtitle',attrs,false),on),[_vm._v(_vm._s(dish.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(dish.description))])]),(dish.allergens && dish.allergens.length > 0)?_c('v-card-text',{staticStyle:{"padding":"0px"}},[_vm._v(" "+_vm._s(_vm.$t("allergens"))+": "+_vm._s(dish.allergens.join(", "))+" ")]):_vm._e()],1),_c('v-card-text',{staticStyle:{"padding-left":"0px","padding-bottom":"0px"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"padding-bottom":"0px"}},[_vm._v(_vm._s(_vm.calculateDishPrice(dish).toFixed(2))+_vm._s(_vm.currencySymbol))]),_vm._v(" /"+_vm._s(dish.amount)+" "),(_vm.mandatoryOptionsProvided(dish))?_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary","small":"","disabled":(!_vm.isOpen && !_vm.allowPreorderingWhenClosed) ||
                      date.disabled ||
                      (!dish.allowedDeliveryMethods.takeaway &&
                        !dish.allowedDeliveryMethods.delivery &&
                        !dish.allowedDeliveryMethods.inplace) ||
                      !_vm.mandatoryOptionsProvided(dish) ||
                      !_vm.isSelectedDeliveryMethodAllowed(dish)},nativeOn:{"click":function($event){$event.stopPropagation();_vm.$emit('add-dish', {
                        dish: dish,
                        options: _vm.selectedOptions[dish._id].filter(
                          function (e) { return dish.options.indexOf(e._id) !== -1; }
                        ),
                        price: _vm.calculateDishPrice(dish),
                        preorder: date.preordering,
                      });
                      _vm.resetOptions(dish);}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")]):_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary","small":"","disabled":(!_vm.isOpen && !_vm.allowPreorderingWhenClosed) ||
                      date.disabled ||
                      (!dish.allowedDeliveryMethods.takeaway &&
                        !dish.allowedDeliveryMethods.delivery &&
                        !dish.allowedDeliveryMethods.inplace) ||
                      !_vm.isSelectedDeliveryMethodAllowed(dish)}},[_vm._v(" "+_vm._s(_vm.$t("selectOptions"))+" ")]),(!date.disabled)?_c('div',[_c('AllowedDeliveryMethods',{attrs:{"allowedDeliveryMethods":dish.allowedDeliveryMethods}})],1):_vm._e()],1)],1)],1),_c('v-expansion-panel-content',[(dish.options.length !== 0)?_c('Options',{staticClass:"options mb-2",attrs:{"currencySymbol":_vm.currencySymbol,"dish":dish,"options":_vm.options,"selectedOptions":_vm.selectedOptions[dish._id],"expandFirstOption":_vm.expandFirstOption},on:{"add-option":function($event){return _vm.addOption($event, dish)},"remove-option":function($event){return _vm.removeOption($event, dish)}}}):_vm._e()],1)],1)}),1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }