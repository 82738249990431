<template>
  <div piknik style="overflow: visible !important">
    <v-app>
      <v-container>
        <v-skeleton-loader
          max-width="750px"
          type="table-tfoot, card-heading, list-item-two-line, article, card-heading, list-item, article, card-heading, list-item-two-line"
          :types="{ 'table-tfoot': 'text@5' }"
          :loading="loading"
        >
          <Header
            :isOpen="isOpen"
            :orderScheduling="orderScheduling"
            :allowPreorderingWhenClosed="allowPreorderingWhenClosed"
            :dailyMenuLink="
              display_only !== 'dishes' &&
              dailyMenuData &&
              dailyMenuData.length !== 0
            "
            :linkSlider="linkSlider"
            :categories="display_only === 'dailyMenu' ? [] : categories"
            :order="order"
            :showArrows="show_arrows"
            :customHeadings="customHeadings"
            v-on:open-shopping-cart="shoppingCartStatus = true"
            v-on:open-time-selecting="timeSelectingStatus = true"
          />

          <v-alert
            v-if="loyaltyProgram && loyaltyProgram.status"
            type="info"
            color="primary"
            icon="mdi-diamond-stone"
            class="mb-0"
            text
            dense
            >{{ loyaltyProgramText }}</v-alert
          >

          <v-fab-transition v-if="floating_button === 'true'">
            <v-btn
              style="z-index: 999"
              color="primary"
              dark
              fixed
              bottom
              right
              fab
              @click="shoppingCartStatus = true"
              large
            >
              <v-badge
                :content="orderItemsQuantity"
                :value="order.length !== 0"
              >
                <v-icon>mdi-shopping</v-icon>
              </v-badge>
            </v-btn>
          </v-fab-transition>

          <DailyMenu
            v-if="
              display_only !== 'dishes' &&
              dailyMenuData &&
              dailyMenuData.length !== 0
            "
            :isOpen="isOpen"
            :allowPreorderingWhenClosed="allowPreorderingWhenClosed"
            :currencySymbol="currencySymbol"
            :customHeadings="customHeadings"
            :dailyMenuOrderingTime="dailyMenuOrderingTime"
            :data="dailyMenuData"
            :options="options"
            :order="order"
            :expandFirstOption="expand_first_option"
            :imageContain="image_contain"
            v-on:add-dish="addDish($event)"
          />

          <Dishes
            v-if="display_only !== 'dailyMenu'"
            :isOpen="isOpen"
            :allowPreorderingWhenClosed="allowPreorderingWhenClosed"
            :currencySymbol="currencySymbol"
            :customHeadings="customHeadings"
            :data="data"
            :options="options"
            :order="order"
            :expandFirstOption="expand_first_option"
            :imageContain="image_contain"
            :imagePlaceholderUrl="image_placeholder_url"
            v-on:add-dish="addDish($event)"
          />
        </v-skeleton-loader>
      </v-container>

      <v-bottom-sheet
        v-if="$vuetify.breakpoint.smAndDown"
        class="shopping-cart"
        v-model="timeSelectingStatus"
        max-width="500px"
        scrollable
      >
        <TimeSelecting
          :date="scheduledAt.date"
          :time="scheduledAt.time"
          :openingHours="openingHours"
          v-on:change-scheduled-at-time="changeScheduledAtTime($event)"
        />
      </v-bottom-sheet>
      <v-dialog
        v-else
        class="shopping-cart"
        v-model="timeSelectingStatus"
        max-width="400px"
        scrollable
      >
        <TimeSelecting
          :date="scheduledAt.date"
          :time="scheduledAt.time"
          :openingHours="openingHours"
          v-on:change-scheduled-at-time="changeScheduledAtTime($event)"
        />
      </v-dialog>

      <v-bottom-sheet
        v-if="$vuetify.breakpoint.smAndDown"
        class="shopping-cart"
        v-model="shoppingCartStatus"
        max-width="500px"
        scrollable
      >
        <ShoppingCart
          :restaurantId="restaurant_id"
          :isOpen="isOpen"
          :orderScheduling="orderScheduling"
          :scheduledAt="scheduledAt"
          :allowPreorderingWhenClosed="allowPreorderingWhenClosed"
          :isPreorder="isPreorder"
          :currencySymbol="currencySymbol"
          :minimalOrderValue="minimalOrderValue"
          :defaultMinimalOrderValue="defaultMinimalOrderValue"
          :deliveryOptions="deliveryOptions"
          :paymentOptions="paymentOptions"
          :status="shoppingCartStatus"
          :order="order"
          :orderTotal="orderTotal"
          :orderTotalAfterDiscount="orderTotalAfterDiscount"
          :loyaltyProgram="loyaltyProgram"
          :customersLoyaltyCredits="customersLoyaltyCredits"
          :stripe="stripe"
          :agreementsUrl="agreements_url"
          :gdprUrl="gdpr_url"
          :imagePlaceholderUrl="image_placeholder_url"
          v-on:add-dish="addDish($event)"
          v-on:remove-dish="removeDish($event)"
          v-on:close-shopping-cart="shoppingCartStatus = false"
          v-on:change-minimal-order-value="minimalOrderValue = $event"
          v-on:change-customer="fetchLoyaltyCredits($event)"
          v-on:clear-order="order = []"
        />
      </v-bottom-sheet>
      <v-dialog
        v-else
        class="shopping-cart"
        v-model="shoppingCartStatus"
        max-width="600px"
        scrollable
      >
        <ShoppingCart
          :restaurantId="restaurant_id"
          :isOpen="isOpen"
          :orderScheduling="orderScheduling"
          :scheduledAt="scheduledAt"
          :allowPreorderingWhenClosed="allowPreorderingWhenClosed"
          :isPreorder="isPreorder"
          :currencySymbol="currencySymbol"
          :minimalOrderValue="minimalOrderValue"
          :defaultMinimalOrderValue="defaultMinimalOrderValue"
          :deliveryOptions="deliveryOptions"
          :paymentOptions="paymentOptions"
          :status="shoppingCartStatus"
          :order="order"
          :orderTotal="orderTotal"
          :orderTotalAfterDiscount="orderTotalAfterDiscount"
          :loyaltyProgram="loyaltyProgram"
          :customersLoyaltyCredits="customersLoyaltyCredits"
          :stripe="stripe"
          :agreementsUrl="agreements_url"
          :gdprUrl="gdpr_url"
          :imagePlaceholderUrl="image_placeholder_url"
          v-on:add-dish="addDish($event)"
          v-on:remove-dish="removeDish($event)"
          v-on:close-shopping-cart="shoppingCartStatus = false"
          v-on:change-minimal-order-value="minimalOrderValue = $event"
          v-on:change-customer="fetchLoyaltyCredits($event)"
          v-on:clear-order="order = []"
        />
      </v-dialog>

      <v-snackbar v-model="isOpenSnackbar" :timeout="-1" bottom>
        {{
          allowPreorderingWhenClosed
            ? $t("isOpenFalseButAcceptingPreorders")
            : $t("isOpenFalse")
        }}
        ({{ willOpenOnAt }})
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            rounded
            v-bind="attrs"
            @click="
              isOpenSnackbar = false;
              allowPreorderingWhenClosed ? (scheduledAtSnackbar = true) : null;
            "
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-if="orderScheduling"
        v-model="scheduledAtSnackbar"
        :timeout="-1"
        bottom
      >
        {{
          $t("yourOrderIsScheduledAt", {
            day:
              new Date().setHours(0, 0, 0, 0) ===
              new Date(this.scheduledAt.date).setHours(0, 0, 0, 0)
                ? $t("today").toLowerCase() +
                  ` (${new Date(this.scheduledAt.date)
                    .toLocaleDateString()
                    .replace(/ /g, "")})`
                : $t("tomorrow").toLowerCase() +
                  ` (${new Date(this.scheduledAt.date)
                    .toLocaleDateString()
                    .replace(/ /g, "")})`,
            time: this.scheduledAt.time,
          })
        }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            rounded
            v-bind="attrs"
            @click="
              scheduledAtSnackbar = false;
              timeSelectingStatus = true;
            "
          >
            {{ $t("change") }}
          </v-btn>
          <v-btn
            text
            rounded
            v-bind="attrs"
            @click="scheduledAtSnackbar = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { API_URL } from "./api.js";
import demo_data from "./demo-data.js";
import Header from "./components/Header";
import DailyMenu from "./components/DailyMenu";
import Dishes from "./components/Dishes";
import ShoppingCart from "./components/ShoppingCart";
import TimeSelecting from "./components/TimeSelecting";

export default {
  props: {
    restaurant_id: {
      type: String,
      default: "demo",
    },
    primary_color: {
      type: String,
      default: "#0360ec",
    },
    display_only: {
      type: String,
      default: "",
    },
    floating_button: {
      type: String,
      default: "false",
    },
    show_arrows: {
      type: String,
      default: "true",
    },
    expand_first_option: {
      type: String,
      default: "true",
    },
    image_contain: {
      type: String,
      default: "false",
    },
    image_placeholder_url: {
      type: String,
      default: "",
    },
    agreements_url: {
      type: String,
      default: "/agreements",
    },
    gdpr_url: {
      type: String,
      default: "https://piknik.cc/gdpr",
    },
  },
  components: {
    Header,
    DailyMenu,
    Dishes,
    ShoppingCart,
    TimeSelecting,
  },
  data: () => ({
    isOpenSnackbar: false,
    scheduledAtSnackbar: false,
    currentRoute: window.location.pathname,
    loading: true,
    stripe: null,
    tick: false,
    linkSlider: 0,
    dailyMenuData: null,
    data: null,
    shoppingCartStatus: false,
    timeSelectingStatus: false,
    isOpen: null,
    allowPreorderingWhenClosed: null,
    orderScheduling: null,
    openingHours: null,
    deliveryOptions: null,
    paymentOptions: null,
    minimalOrderValue: null,
    defaultMinimalOrderValue: null,
    dailyMenuOrderingTime: null,
    customHeadings: null,
    currencySymbol: "€",
    categories: [],
    options: [],
    dailyMenu: [],
    dishes: [],
    order: [],
    scheduledAt: {
      date: null,
      time: null,
    },
    loyaltyProgram: null,
    customersLoyaltyCredits: null,
  }),
  computed: {
    willOpenOnAt: function () {
      console.log("willOpenOnAt")
      if (this.openingHours) {
        const today = new Date();
        const todayIndex = (today.getDay() || 7) - 1;

        const endDate = new Date();
        endDate.setHours(this.openingHours[todayIndex].to.split(":")[0]);
        endDate.setMinutes(this.openingHours[todayIndex].to.split(":")[1]);

        let opensOnDay = null;
        let opensOnTime = null;

        const opensToday = today < endDate;
        if (opensToday)
          return this.$t("opensTodayAt", {
            time: this.openingHours[todayIndex].from,
          });

        let i = todayIndex === 6 ? todayIndex : todayIndex + 1
        for (i; i < this.openingHours.length; i++) {
          console.log(i)
          const e = this.openingHours[i];
          if (e.to !== "00:00") {
            opensOnDay = e.day;
            opensOnTime = e.from;
            break;
          }
          if (i === 6 && !opensOnDay && !opensOnTime) i = -1;
        }
        return this.$t("opensOnAt", {
          day: this.$t(opensOnDay).toLowerCase(),
          time: opensOnTime,
        });
      }
      return "";
    },
    orderTotal: function () {
      let total = 0;
      this.order.forEach((dish) => {
        total += Number(dish.price * dish.quantity);
      });
      return Math.round(total * 1e2) / 1e2;
    },
    orderTotalAfterDiscount: function () {
      let total = this.orderTotal;
      if (
        this.loyaltyProgram &&
        this.loyaltyProgram.status &&
        this.customersLoyaltyCredits > 0
      )
        total =
          Math.round((total - this.customersLoyaltyCredits / 100) * 1e2) / 1e2;
      return total > 0 ? total : 0;
    },
    orderItemsQuantity: function () {
      let quantity = 0;
      this.order.forEach((dish) => {
        quantity += dish.quantity;
      });
      return quantity;
    },
    isPreorder: function () {
      if (!this.isOpen && this.allowPreorderingWhenClosed) return true;
      for (let i = 0; i < this.order.length; i++) {
        if (this.order[i].preorder) return true;
      }
      return false;
    },
    loyaltyProgramText: function () {
      if (this.customersLoyaltyCredits > 0)
        return this.$t("youHaveLoyaltyCreditsToSpend", {
          loyaltyCredits: this.customersLoyaltyCredits,
          amount: (this.customersLoyaltyCredits / 100).toFixed(2),
          currency: this.currencySymbol,
        });
      return this.$t("orderAndEarnLoyaltyCredits", {
        percentage: this.loyaltyProgram.loyaltyCreditsPercentage,
      });
    },
  },
  mounted: function () {
    // this.$i18n.locale = this.default_language;
    this.$vuetify.theme.themes.light.primary = this.primary_color;

    if (this.restaurant_id === "demo") {
      this.isOpen = demo_data.isOpen;
      this.allowPreorderingWhenClosed = false;
      this.isOpenSnackbar = !this.isOpen;
      this.dailyMenuOrderingTime = demo_data.dailyMenuOrderingTime;
      this.categories = demo_data.categories;
      this.options = demo_data.options;
      this.dailyMenu = demo_data.dailyMenu.filter(
        (dish) =>
          new Date().setHours(0, 0, 0, 0) <=
          new Date(dish.date).setHours(0, 0, 0, 0)
      );
      this.dishes = demo_data.dishes;
      this.generateData();
      this.generateDailyMenuData();
      this.loading = false;
    } else
      fetch(
        `${API_URL}/restaurant/${this.restaurant_id}?pick=paymentOptions,isOpen,allowPreorderingWhenClosed,orderScheduling,info,deliveryOptions,minimalOrderValue,dailyMenuOrderingTime,categories,options,customHeadings,currency,loyaltyProgram`
      )
        .then((response) => response.json())
        .then((data) => {
          this.stripe = window.Stripe(
            "pk_live_51HxU33DxFtdn9X2RhCJ8BNMHE6sSXiQ7KTKmQHXw6QJ7DiyvQA7bWwPqZOjuvTnMR4D3BvYrsCF9wvVNmHraLu0v00ITZ08n1b",
            {
              stripeAccount: data.paymentOptions.onlinePayments.stripeAccountId,
            }
          );
          this.isOpen = data.isOpen;
          this.allowPreorderingWhenClosed = data.allowPreorderingWhenClosed;
          this.orderScheduling = data.orderScheduling;
          this.openingHours = data.info.openingHours;
          this.isOpenSnackbar = !data.isOpen;
          this.deliveryOptions = data.deliveryOptions;
          this.paymentOptions = data.paymentOptions;
          this.minimalOrderValue = data.minimalOrderValue;
          this.defaultMinimalOrderValue = data.minimalOrderValue;
          this.dailyMenuOrderingTime = data.dailyMenuOrderingTime;
          this.categories = data.categories;
          this.options = data.options;
          this.customHeadings = data.customHeadings;
          this.loyaltyProgram = data.loyaltyProgram;

          if (data.currency === "EUR") this.currencySymbol = " €";
          else if (data.currency === "CZK") this.currencySymbol = " Kč";

          this.initializeScheduledAt();
          this.fetchLoyaltyCredits();

          if (this.isOpen) this.scheduledAtSnackbar = true;

          const params = new URLSearchParams(window.location.search);
          if (params.get("status")) this.shoppingCartStatus = true;
        });

    window.addEventListener("scroll", () => {
      if (!this.tick) {
        setTimeout(
          function () {
            this.updateActiveCategoryLink();
            this.tick = false;
          }.bind(this),
          100
        );
      }
      this.tick = true;
    });
  },
  methods: {
    fetchLoyaltyCredits: function (customer = null) {
      try {
        if (this.loyaltyProgram && this.loyaltyProgram.status) {
          customer = customer || JSON.parse(localStorage.getItem("customer"));
          if (customer) {
            fetch(
              `${API_URL}/customer/loyaltycredits?restaurantId=${this.restaurant_id}&email=${customer.email}&mobile=${customer.mobile}`
            )
              .then((response) => response.json())
              .then((data) => {
                this.customersLoyaltyCredits = data.loyaltyCredits;
              });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    fetchAgainOnScheduledAtChange: function () {
      this.loading = true;
      const scheduledAtDate = new Date(this.scheduledAt.date);
      scheduledAtDate.setHours(this.scheduledAt.time.split(":")[0]);
      scheduledAtDate.setMinutes(this.scheduledAt.time.split(":")[1]);
      fetch(
        `${API_URL}/restaurant/${
          this.restaurant_id
        }?pick=dailyMenu,dishes&scheduledAt=${scheduledAtDate.toISOString()}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.dailyMenu = data.dailyMenu.filter(
            (dish) =>
              new Date().setHours(0, 0, 0, 0) <=
              new Date(dish.date).setHours(0, 0, 0, 0)
          );
          this.dishes = data.dishes;

          this.generateData();
          this.generateDailyMenuData();

          this.loading = false;
          this.updateAllowedDeliveryMethodsOfItemsInOrder();
        });
    },
    updateAllowedDeliveryMethodsOfItemsInOrder: function () {
      const menu = [...this.dailyMenu, ...this.dishes];
      const removedDishIds = [];
      this.order.forEach((dish, index) => {
        const dishId = dish._id;
        const found = menu.find((e) => e._id === dishId);
        if (found)
          this.order[index].allowedDeliveryMethods =
            found.allowedDeliveryMethods;
        else {
          removedDishIds.push(dishId);
        }
      });
      this.order = this.order.filter((e) => !removedDishIds.includes(e._id));
      if (removedDishIds.length > 0) this.updateScheduledAtAfterRemovingDish();
    },
    initializeScheduledAt: function () {
      const today = new Date();
      const todayIndex = (today.getDay() || 7) - 1;

      const endDate = new Date();
      endDate.setHours(this.openingHours[todayIndex].to.split(":")[0]);
      endDate.setMinutes(this.openingHours[todayIndex].to.split(":")[1]);

      // scheduledAt.date
      this.scheduledAt.date = new Date().toISOString().split("T")[0];
      if (this.isOpen && today > endDate) {
        this.isOpen = false;
        this.isOpenSnackbar = !this.isOpen;
      }
      if (!this.isOpen && this.allowPreorderingWhenClosed) {
        if (today > endDate) {
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          this.scheduledAt.date = tomorrow.toISOString().split("T")[0];

          // disable ordering if it is closed on next day
          const scheduledAtDateIndex = (tomorrow.getDay() || 7) - 1;
          const tomorrowEndHours = this.openingHours[scheduledAtDateIndex].to;
          if (tomorrowEndHours === "00:00")
            this.allowPreorderingWhenClosed = false;
        }
      }

      // scheduledAt.time
      this.updateScheduledAtTimeBasedOnScheduledAtDate();
    },
    updateScheduledAtTimeBasedOnScheduledAtDate: function () {
      const today = new Date();

      const scheduledAtDate = new Date(this.scheduledAt.date);
      scheduledAtDate.setHours(today.getHours());
      scheduledAtDate.setMinutes(today.getMinutes());
      const scheduledAtDateIndex = (scheduledAtDate.getDay() || 7) - 1;

      const startDate = new Date();
      startDate.setHours(
        this.openingHours[scheduledAtDateIndex].from.split(":")[0]
      );
      startDate.setMinutes(
        this.openingHours[scheduledAtDateIndex].from.split(":")[1]
      );

      const endDate = new Date();
      endDate.setHours(
        this.openingHours[scheduledAtDateIndex].to.split(":")[0]
      );
      endDate.setMinutes(
        this.openingHours[scheduledAtDateIndex].to.split(":")[1]
      );

      if (startDate < scheduledAtDate && endDate > scheduledAtDate)
        this.scheduledAt.time = this.roundTimeUpToQuarter(
          scheduledAtDate
            .toTimeString()
            .split(" ")[0]
            .replace(/:.{1,2}$/, "")
        );
      else
        this.scheduledAt.time = this.roundTimeUpToQuarter(
          this.openingHours[scheduledAtDateIndex].from
        );

      // update dailymenu and dishes (for new scheduledAt there could be different restrictions)
      this.fetchAgainOnScheduledAtChange();
    },
    updateScheduledAtTimeBasedOnOpeningHours: function () {
      const scheduledAtDate = new Date(this.scheduledAt.date);
      const scheduledAtDateIndex = (scheduledAtDate.getDay() || 7) - 1;

      this.scheduledAt.time = this.roundTimeUpToQuarter(
        this.openingHours[scheduledAtDateIndex].from
      );

      // update dailymenu and dishes (for new scheduledAt there could be different restrictions)
      this.fetchAgainOnScheduledAtChange();
    },
    roundTimeUpToQuarter: function (hhmm) {
      const hours = Number(hhmm.split(":")[0]);
      const minutes = Number(hhmm.split(":")[1]);
      const roundedMinutes = Math.ceil(minutes / 15) * 15;
      if (roundedMinutes === 60)
        return hours === 23 ? "00:00" : `${("0" + (hours + 1)).slice(-2)}:00`;
      return `${("0" + hours).slice(-2)}:${("0" + roundedMinutes).slice(-2)}`;
    },
    updateScheduledAtAfterRemovingDish: function () {
      let isPreorderDailyMenuInOrder = false;
      for (let i = 0; i < this.order.length; i++) {
        if (this.order[i].preorder) return (isPreorderDailyMenuInOrder = true);
      }
      if (!isPreorderDailyMenuInOrder) {
        const today = new Date();
        const todayIndex = (today.getDay() || 7) - 1;

        const endDate = new Date();
        endDate.setHours(this.openingHours[todayIndex].to.split(":")[0]);
        endDate.setMinutes(this.openingHours[todayIndex].to.split(":")[1]);

        if (endDate > today) {
          const newScheduledAtDate = today.toISOString().split("T")[0];
          if (this.scheduledAt.date !== newScheduledAtDate) {
            this.scheduledAt.date = newScheduledAtDate;
            this.updateScheduledAtTimeBasedOnScheduledAtDate();
          }
        }
      }
    },
    changeScheduledAtTime: function (selectedTime) {
      this.timeSelectingStatus = false;
      if (this.scheduledAt.time !== selectedTime) {
        this.scheduledAt.time = selectedTime;
        this.fetchAgainOnScheduledAtChange();
      }
    },
    generateData: function () {
      this.categories = this.categories.sort((a, b) => {
        return a.rank - b.rank;
      });

      let data = this.categories;

      data.forEach((e) => {
        e.items = [];
      });

      this.dishes.forEach((dish) => {
        const index = data.findIndex(
          (category) => category._id === dish.category
        );
        if (index > -1) {
          data[index].items.push(dish);
        }
      });

      this.data = data;
    },
    generateDailyMenuData: function () {
      let data = [];

      const isToday = (dishDate) => {
        return (
          new Date().setHours(0, 0, 0, 0) ===
          new Date(dishDate).setHours(0, 0, 0, 0)
        );
      };

      const isDisabled = (dishDateString) => {
        const dishDate = new Date(dishDateString);
        const currentDate = new Date();

        let startDate = new Date(dishDate.getTime());
        startDate.setHours(this.dailyMenuOrderingTime.from.split(":")[0]);
        startDate.setMinutes(this.dailyMenuOrderingTime.from.split(":")[1]);

        let endDate = new Date(dishDate.getTime());
        endDate.setHours(this.dailyMenuOrderingTime.to.split(":")[0]);
        endDate.setMinutes(this.dailyMenuOrderingTime.to.split(":")[1]);

        if (startDate < endDate)
          return (
            isToday(dishDateString) &&
            startDate < currentDate &&
            endDate > currentDate
          );
        else if (startDate > endDate) {
          // Daily menu pre-ordering
          let startDate2 = new Date(dishDate.getTime());
          startDate2.setDate(dishDate.getDate() - 1);
          startDate2.setHours(this.dailyMenuOrderingTime.from.split(":")[0]);
          startDate2.setMinutes(this.dailyMenuOrderingTime.from.split(":")[1]);

          return startDate2 < currentDate && endDate > currentDate;
        }
      };

      this.dailyMenu.forEach((item) => {
        if (data.findIndex((e) => e.date === item.date) === -1)
          data.push({
            date: item.date,
            items: [],
            disabled: !isDisabled(item.date),
            preordering:
              new Date().setHours(0, 0, 0, 0) !==
              new Date(item.date).setHours(0, 0, 0, 0),
          });
      });

      data.sort((a, b) => (a.date > b.date ? 1 : -1));

      this.dailyMenu.forEach((item) => {
        const index = data.findIndex((e) => e.date === item.date);
        if (index > -1) {
          data[index].items.push(item);
        }
      });

      this.dailyMenuData = data;
    },
    addDish: function (event) {
      const dish = event.dish;
      const options = event.options || dish.options || [];
      const price = event.price || dish.price;
      const index = this.order.findIndex(
        (item) =>
          item._id === dish._id &&
          JSON.stringify(item.options) === JSON.stringify(options)
      );
      const preorder = event.preorder;
      if (preorder) {
        this.scheduledAt.date = dish.date;
        this.updateScheduledAtTimeBasedOnOpeningHours();
      }

      if (index > -1) this.order[index].quantity++;
      else
        this.order.push({
          _id: dish._id,
          allowedDeliveryMethods: dish.allowedDeliveryMethods,
          name: dish.name,
          description: dish.description,
          imageUrl: dish.imageUrl,
          options: JSON.parse(JSON.stringify(options)),
          price: price,
          quantity: 1,
          preorder: preorder,
        });
    },
    removeDish: function (dish) {
      const index = this.order.findIndex(
        (item) =>
          item._id === dish._id &&
          JSON.stringify(item.options) === JSON.stringify(dish.options)
      );

      if (dish.quantity > 1) this.order[index].quantity--;
      else {
        this.order.splice(index, 1);
        this.updateScheduledAtAfterRemovingDish();
      }
    },
    updateActiveCategoryLink: function () {
      let mainNavLinks = document.querySelectorAll(".category-link");
      let fromTop =
        window.scrollY - document.querySelector("piknik-embeddable").offsetTop;

      mainNavLinks.forEach((link, i) => {
        let section = document.getElementById(link.hash.split("#")[1]);

        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        )
          this.linkSlider = i;
      });
    },
  },
};
</script>

<style>
[piknik] .col,
[piknik] .col-1,
[piknik] .col-2,
[piknik] .col-3,
[piknik] .col-4,
[piknik] .col-5,
[piknik] .col-6,
[piknik] .col-7,
[piknik] .col-8,
[piknik] .col-9,
[piknik] .col-10,
[piknik] .col-11,
[piknik] .col-12,
[piknik] .col-auto,
[piknik] .col-lg,
[piknik] .col-lg-1,
[piknik] .col-lg-2,
[piknik] .col-lg-3,
[piknik] .col-lg-4,
[piknik] .col-lg-5,
[piknik] .col-lg-6,
[piknik] .col-lg-7,
[piknik] .col-lg-8,
[piknik] .col-lg-9,
[piknik] .col-lg-10,
[piknik] .col-lg-11,
[piknik] .col-lg-12,
[piknik] .col-lg-auto,
[piknik] .col-md,
[piknik] .col-md-1,
[piknik] .col-md-2,
[piknik] .col-md-3,
[piknik] .col-md-4,
[piknik] .col-md-5,
[piknik] .col-md-6,
[piknik] .col-md-7,
[piknik] .col-md-8,
[piknik] .col-md-9,
[piknik] .col-md-10,
[piknik] .col-md-11,
[piknik] .col-md-12,
[piknik] .col-md-auto,
[piknik] .col-sm,
[piknik] .col-sm-1,
[piknik] .col-sm-2,
[piknik] .col-sm-3,
[piknik] .col-sm-4,
[piknik] .col-sm-5,
[piknik] .col-sm-6,
[piknik] .col-sm-7,
[piknik] .col-sm-8,
[piknik] .col-sm-9,
[piknik] .col-sm-10,
[piknik] .col-sm-11,
[piknik] .col-sm-12,
[piknik] .col-sm-auto,
[piknik] .col-xl,
[piknik] .col-xl-1,
[piknik] .col-xl-2,
[piknik] .col-xl-3,
[piknik] .col-xl-4,
[piknik] .col-xl-5,
[piknik] .col-xl-6,
[piknik] .col-xl-7,
[piknik] .col-xl-8,
[piknik] .col-xl-9,
[piknik] .col-xl-10,
[piknik] .col-xl-11,
[piknik] .col-xl-12,
[piknik] .col-xl-auto {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

html {
  scroll-behavior: smooth;
}

[piknik] .v-application--wrap {
  min-height: auto !important;
}

[piknik] .image-thumbnail {
  padding-right: 12px !important;
}

[piknik] .v-snack.v-snack--bottom {
  top: auto;
}
</style>