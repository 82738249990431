<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="mr-1 d-inline-block">
        <v-icon v-if="allowedDeliveryMethods.delivery" class="mr-1" small>
          mdi-truck-delivery
        </v-icon>
        <v-icon v-if="allowedDeliveryMethods.takeaway" class="mr-1" small>
          mdi-walk
        </v-icon>
        <v-icon v-if="allowedDeliveryMethods.inplace" small>
          mdi-silverware-fork-knife
        </v-icon>
      </span>
    </template>
    <span>{{ getAllowedDeliveryMethods(allowedDeliveryMethods) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AllowedDeliveryMethods",
  props: ["allowedDeliveryMethods"],
  methods: {
    getAllowedDeliveryMethods: function (allowedDeliveryMethods) {
      let string = `${this.$t("deliveryMethods")}:`;
      if (allowedDeliveryMethods.delivery)
        string += ` ${this.$t("delivery").toLowerCase()},`;
      if (allowedDeliveryMethods.takeaway)
        string += ` ${this.$t("takeaway").toLowerCase()},`;
      if (allowedDeliveryMethods.inplace)
        string += ` ${this.$t("inplace").toLowerCase()},`;
      return string.replace(/,$/, "");
    },
  },
};
</script>

<style>
</style>