<template>
  <v-card min-height="100px" class="px-2">
    <v-card-title class="headline font-weight-black py-4">
      {{ $t("timeSelecting") }}
      <span class="ml-auto">
        <v-btn
          text
          rounded
          color="#13e46d"
          @click="$emit('change-scheduled-at-time', selectedTime)"
        >
          {{ $t("save") }}
        </v-btn>
      </span>
    </v-card-title>
    <v-card-text class="pb-4">
      <v-text-field
        :value="
          isToday(date)
            ? `${$t('today')} (${getPrettyDate(date)})`
            : `${$t('tomorrow')} (${getPrettyDate(date)})`
        "
        single-line
        readonly
        filled
        dense
        rounded
        hide-details
        class="mb-2"
      ></v-text-field>
      <v-select
        v-model="selectedTime"
        :items="getTimes()"
        :hint="$t('selectTimeHint')"
        :no-data-text="$t('isOpenFalse')"
        persistent-hint
        menu-props="auto"
        single-line
        filled
        rounded
      ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TimeSelecting",
  props: ["date", "time", "openingHours"],
  data: () => ({
    selectedTime: null,
  }),
  mounted: function () {
    this.selectedTime = this.time;
  },
  watch: {
    time: function (newVal) {
      this.selectedTime = newVal;
    },
  },
  methods: {
    getTimes: function () {
      const roundTimeUpToQuarter = (hhmm) => {
        const hours = Number(hhmm.split(":")[0]);
        const minutes = Number(hhmm.split(":")[1]);
        const roundedMinutes = Math.ceil(minutes / 15) * 15;
        if (roundedMinutes === 60)
          return hours === 23 ? "00:00" : `${("0" + (hours + 1)).slice(-2)}:00`;
        return `${("0" + hours).slice(-2)}:${("0" + roundedMinutes).slice(-2)}`;
      };

      const x = 15;
      let times = [];
      let tt = 0;

      for (let i = 0; tt <= 24 * 60; i++) {
        let hh = Math.floor(tt / 60);
        let mm = tt % 60;
        times[i] = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2);
        tt = tt + x;
      }

      const scheduledAtDate = new Date(this.date);
      const scheduledAtDateIndex = (scheduledAtDate.getDay() || 7) - 1;

      // do not show 00:00 when closed
      const tomorrowEndHours = this.openingHours[scheduledAtDateIndex].to;
      if (tomorrowEndHours === "00:00") return [];

      // need to implement logic to filter out past times
      const currentTime = new Date()
        .toTimeString()
        .split(" ")[0]
        .replace(/:.{1,2}$/, "");
      const currentTimeRounded = roundTimeUpToQuarter(currentTime);

      const startTime = this.openingHours[scheduledAtDateIndex].from;
      const startTimeIndex =
        currentTimeRounded > startTime && this.isToday(this.date)
          ? times.indexOf(currentTimeRounded)
          : times.indexOf(startTime);

      const endTimeIndex = times.indexOf(
        this.openingHours[scheduledAtDateIndex].to
      );

      return times.slice(startTimeIndex, endTimeIndex + 1);
    },
    isToday: function (dateString) {
      return (
        new Date().setHours(0, 0, 0, 0) ===
        new Date(dateString).setHours(0, 0, 0, 0)
      );
    },
    getPrettyDate: function (dateString) {
      return new Date(dateString).toLocaleDateString().replace(/ /g, "");
    },
  },
};
</script>