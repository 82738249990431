<template>
  <div>
    <v-card
      class="mx-auto mb-4"
      flat
      rounded
      color="rgba(0, 0, 0, 0.06)"
      style="border-radius: 28px !important"
    >
      <v-list-item three-line>
        <v-list-item-avatar color="primary">
          <span class="white--text">{{
            customer.name
              .split(" ")
              .map((n) => n[0])
              .join("")
          }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="">{{ customer.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
          <v-list-item-subtitle class="mb-2">{{
            customer.mobile
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="deliveryMethod === 'delivery'"
            class="font-weight-bold"
            >{{ customer.address.street }}, {{ customer.address.zip }}
            {{ customer.address.city }}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            v-else-if="deliveryMethod === 'takeaway'"
            class="font-weight-bold"
            >{{ $t("personalPickup") }}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            v-else-if="deliveryMethod === 'inplace' && !deliveryDetails.tableId"
            class="font-weight-bold"
            >{{
              $t("inPlaceAt", {
                time: scheduledAt.time,
                persons: deliveryDetails.persons,
              })
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            v-else-if="deliveryMethod === 'inplace' && deliveryDetails.tableId"
            class="font-weight-bold"
            >{{
              $t("inPlaceTable", { table: deliveryDetails.tableId })
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="font-weight-bold">
            <span v-if="isPreorder"> {{ $t("preOrder") }}</span>
            <span v-if="isPreorder && orderScheduling"> - </span>
            <span v-if="orderScheduling">
              {{
                $t("scheduledAt", {
                  day:
                    new Date().setHours(0, 0, 0, 0) ===
                    new Date(this.scheduledAt.date).setHours(0, 0, 0, 0)
                      ? $t("today").toLowerCase() +
                        ` (${new Date(this.scheduledAt.date)
                          .toLocaleDateString()
                          .replace(/ /g, "")})`
                      : $t("tomorrow").toLowerCase() +
                        ` (${new Date(this.scheduledAt.date)
                          .toLocaleDateString()
                          .replace(/ /g, "")})`,
                  time: this.scheduledAt.time,
                })
              }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-checkbox
      v-model="agreement"
      class="pa-0 ma-0 mb-4"
      hide-details
      color="primary"
      v-on:click="$emit('agreement-change', agreement)"
    >
      <template v-slot:label>
        <div>
          {{ $t("aggreeWith") }}
          <a
            class="primary--text text-decoration-none"
            :href="agreementsUrl"
            target="_blank"
            @click.stop
            rel="noopener noreferrer"
            >{{ $t("withOrderingAgreements") }}</a
          >
          {{ $t("andWith") }}
          <a
            class="primary--text text-decoration-none"
            :href="gdprUrl"
            target="_blank"
            @click.stop
            rel="noopener noreferrer"
            >{{ $t("withGDPRAgreements") }}</a
          >.
        </div>
      </template></v-checkbox
    >

    <v-alert
      v-if="earnedLoyaltyCredits"
      type="info"
      color="primary"
      icon="mdi-diamond-stone"
      class="mb-4"
      text
      dense
      >{{
        $t("byCompletingTheOrderYouWillEarnLoyaltyCreditsValidFor", {
          loyaltyCredits: earnedLoyaltyCredits,
          validityInDays: loyaltyProgram.loyaltyCreditsValidityInDays,
        })
      }}</v-alert
    >
    <v-img
      class="mb-4"
      height="6px"
      src="https://embeddable.piknik.cc/img/devider.svg"
    ></v-img>

    <v-expansion-panels accordion readonly>
      <v-expansion-panel
        v-for="(dish, i) in order"
        :key="i"
        active-class="active"
      >
        <v-expansion-panel-header hide-actions>
          <v-col cols="12">
            <div></div>
            <v-card-title style="padding-left: 0px; padding-top: 0px">
              <v-list-item-title style="font-size: 20px"
                ><span style="margin-right: 8px" class="primary--text"
                  >{{ dish.quantity }}x</span
                >{{ dish.name }}</v-list-item-title
              >
            </v-card-title>
            <v-card-subtitle style="padding-left: 0px; padding-bottom: 0">
              <span
                v-if="optionsToString(dish) !== dish.description"
                class="short-description"
                ><span style="margin-right: 4px" class="primary--text"
                  >+ {{ dish.quantity }}x</span
                >({{ optionsToString(dish) }})</span
              >
              <v-list-item-subtitle v-else class="short-description">{{
                dish.description
              }}</v-list-item-subtitle>
            </v-card-subtitle>
          </v-col>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { API_URL } from "../api.js";

export default {
  name: "Header",
  props: [
    "isPreorder",
    "orderScheduling",
    "scheduledAt",
    "currencySymbol",
    "order",
    "deliveryMethod",
    "deliveryDetails",
    "customer",
    "note",
    "loyaltyProgram",
    "earnedLoyaltyCredits",
    "onlinePayment",
    "stripe",
    "agreementsUrl",
    "gdprUrl",
  ],
  data: () => ({
    agreement: false,
  }),
  methods: {
    optionsToString: function (dish) {
      const options = dish.options;
      let string = "";
      options.forEach((option) => {
        option.values.forEach((value) => {
          if (value.quantity > 1)
            string += `${value.quantity}x ${value.name}, `;
          else string += `${value.name}, `;
        });
      });
      if (string.length === 0) return dish.description;
      return string.slice(0, -2);
    },
    sendOrder: async function (restaurantId) {
      try {
        const scheduledAtDate = new Date(this.scheduledAt.date);
        scheduledAtDate.setHours(this.scheduledAt.time.split(":")[0]);
        scheduledAtDate.setMinutes(this.scheduledAt.time.split(":")[1]);

        let deliveryDetails = this.deliveryDetails;
        if (this.deliveryMethod === "inplace" && !this.deliveryDetails.tableId)
          deliveryDetails.time = this.scheduledAt.time;

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          referrerPolicy: "no-referrer-when-downgrade",
          body: JSON.stringify({
            restaurantId: `${restaurantId}`,
            deliveryMethod: this.deliveryMethod,
            deliveryDetails: deliveryDetails,
            customer: this.customer,
            items: this.order,
            note: `${this.note}`,
            onlinePayment: this.onlinePayment,
            preorder: this.isPreorder,
            scheduledAt: this.orderScheduling
              ? scheduledAtDate.toISOString()
              : null,
          }),
        };

        const response = await fetch(`${API_URL}/order/create`, requestOptions);
        const data = await response.json();

        // try {
        //   fbq("track", "Purchase", { value: 0.0, currency: "USD" });
        //   ttq.track("PlaceAnOrder", { value: 0.0, currency: "USD" });
        // } catch (error) {
        //   console.log("Tracking disabled od no Pixel installed!");
        // }

        if (this.onlinePayment) {
          this.stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          return data;
        } else {
          this.agreement = false;
          this.$emit("agreement-change", false);
          this.$emit("clear-order");
          return data;
        }
      } catch {
        this.agreement = false;
        this.$emit("agreement-change", false);
        this.$emit("clear-order");
        return null;
      }
    },
  },
};
</script>

<style>
.v-expansion-panel:first-child .col {
  padding-top: 0 !important;
}

.v-expansion-panel:last-child .col {
  padding-bottom: 0 !important;
}

.custom-blue--text {
  color: #0360ec !important;
  caret-color: #0360ec !important;
  text-decoration: none !important;
}
</style>