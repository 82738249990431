<template>
  <v-toolbar class="categories" flat>
    <v-toolbar-title>
      <v-slide-group
        center-active
        :value="linkSlider"
        :show-arrows="showArrows === 'true' && !$vuetify.breakpoint.xsOnly"
      >
        <v-slide-item
          v-if="dailyMenuLink"
          key="dailyMenu"
          v-slot:default="{ active, toggle }"
        >
          <a
            v-bind:href="`#dailyMenu`"
            class="category-link"
            style="text-decoration: none"
          >
            <v-btn
              class="mx-1"
              :input-value="active"
              active-class="primary--text"
              @click="toggle"
              depressed
              rounded
              small
            >
              {{
                customHeadings && customHeadings.dailyMenu.name
                  ? customHeadings.dailyMenu.name
                  : $t("dailyMenu")
              }}
            </v-btn>
          </a>
        </v-slide-item>
        <v-slide-item
          v-for="category in categories"
          :key="category._id"
          v-slot:default="{ active, toggle }"
        >
          <div v-if="category.items.length !== 0">
            <a
              v-bind:href="`#${category._id}`"
              class="category-link"
              style="text-decoration: none"
            >
              <v-btn
                class="mx-1"
                :input-value="active"
                active-class="primary--text"
                @click="toggle"
                depressed
                rounded
                small
              >
                {{ category.name }}
              </v-btn>
            </a>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="orderScheduling && (isOpen || allowPreorderingWhenClosed)"
      icon
      color="primary"
      v-on:click="$emit('open-time-selecting')"
    >
      <v-icon>mdi-clock</v-icon>
    </v-btn>
    <v-btn icon color="primary" v-on:click="$emit('open-shopping-cart')">
      <v-badge v-if="order.length !== 0" color="primary" dot>
        <v-icon>mdi-shopping</v-icon>
      </v-badge>
      <v-icon v-else>mdi-shopping</v-icon>
    </v-btn>
    <!-- <v-btn icon color="primary" v-on:click="$emit('open-time-selecting')" :small="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'ml-2' : ''">
      <v-icon>mdi-clock</v-icon>
    </v-btn>
    <v-btn icon color="primary" v-on:click="$emit('open-shopping-cart')" :small="$vuetify.breakpoint.xs">
      <v-badge v-if="order.length !== 0" color="primary" dot>
        <v-icon>mdi-shopping</v-icon>
      </v-badge>
      <v-icon v-else>mdi-shopping</v-icon>
    </v-btn> -->
  </v-toolbar>
</template>

<script>
export default {
  name: "Header",
  props: [
    "isOpen",
    "orderScheduling",
    "allowPreorderingWhenClosed",
    "dailyMenuLink",
    "linkSlider",
    "categories",
    "order",
    "showArrows",
    "customHeadings",
  ],
};
</script>

<style>
.custom-blue--text {
  color: #0360ec !important;
  caret-color: #0360ec !important;
}

[piknik] .categories {
  z-index: 998;
  position: sticky !important;
  top: 0;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child,
.v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child {
  margin-right: 0 !important;
}
</style>