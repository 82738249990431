<template>
  <v-expansion-panels accordion :value="expandFirstOption === 'true' ? 0 : null">
    <v-expansion-panel v-for="option in data" :key="option._id">
      <v-expansion-panel-header>
        <!-- {{ option.name }}
        <v-chip
          v-if="option.minimumSelections >= 1"
          outlined
          x-small
          color="#fc010e"
          class="ml-2"
          style="flex: unset !important"
          >povinné</v-chip
        > -->
        <v-icon
          v-if="option.minimumSelections >= 1"
          x-small
          :color="!mandatoryValuesProvided(option) ? '#fc010e' : '#13e46d'"
          class="mr-2"
          style="flex: unset !important"
        >
          mdi-checkbox-blank-circle
        </v-icon>
        {{ option.name }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list subheader dense>
          <v-list-item v-for="value in option.values" :key="value._id">
            <v-list-item-content>
              <v-list-item-title>
                <span
                  v-if="optionValueQuantity(option, value)"
                  class="primary--text"
                  style="margin-right: 4px"
                  >{{ optionValueQuantity(option, value) }}x</span
                >
                {{ value.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                >+ {{ value.price.toFixed(2)
                }}{{ currencySymbol }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <v-btn
                  icon
                  color="#0360ec"
                  small
                  :disabled="
                    optionValueQuantity(option, value) >= value.maximumAmount ||
                    (optionSelections(option) >= option.maximumSelections &&
                      !isValueInValues(option, value))
                  "
                  @click.native.stop="$emit('add-option', { option, value })"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="#fc010e"
                  small
                  :disabled="!optionValueQuantity(option, value)"
                  @click.native.stop="$emit('remove-option', { option, value })"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "Options",
  props: [
    "currencySymbol",
    "dish",
    "options",
    "selectedOptions",
    "expandFirstOption",
  ],
  data: () => ({
    data: [],
  }),
  methods: {
    optionValueQuantity: function (option, value) {
      try {
        const index = this.selectedOptions.findIndex(
          (e) => e._id === option._id
        );
        return this.selectedOptions[index].values.filter(
          (e) => e._id === value._id
        )[0].quantity;
      } catch {
        return false;
      }
    },
    optionSelections: function (option) {
      try {
        const index = this.selectedOptions.findIndex(
          (e) => e._id === option._id
        );
        return this.selectedOptions[index].values.length;
      } catch {
        return 0;
      }
    },
    isValueInValues: function (option, value) {
      try {
        const index = this.selectedOptions.findIndex(
          (e) => e._id === option._id
        );
        return (
          this.selectedOptions[index].values.findIndex(
            (e) => e._id === value._id
          ) !== -1
        );
      } catch {
        return false;
      }
    },
    mandatoryValuesProvided: function (option) {
      return this.optionSelections(option) >= option.minimumSelections;
    },
  },
  mounted: function () {
    this.data = this.options
      .filter((e) => this.dish.options.indexOf(e._id) !== -1)
      .sort(
        (a, b) =>
          this.dish.options.indexOf(a._id) - this.dish.options.indexOf(b._id)
      );
  },
};
</script>

<style scoped>
.v-expansion-panel:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.v-expansion-panel-header {
  padding: 16px 16px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
</style>