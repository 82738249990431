<template>
  <div class="dailyMenu" id="dailyMenu">
    <v-card-title class="headline font-weight-black pl-0 pb-0">
      {{
        customHeadings && customHeadings.dailyMenu.name
          ? customHeadings.dailyMenu.name
          : $t("dailyMenu")
      }}
    </v-card-title>
    <span
      v-if="customHeadings && customHeadings.dailyMenu.description"
      class="subtitle-1"
      >{{ customHeadings.dailyMenu.description }}</span
    >
    <v-expansion-panels
      accordion
      :value="
        data[0].disabled && data[1] && !data[1].disabled
          ? 1
          : data[0].disabled
          ? -1
          : 0
      "
    >
      <v-expansion-panel v-for="(date, i) in data" :key="i">
        <v-expansion-panel-header>
          <div>
            <v-tooltip
              v-if="
                new Date().setHours(0, 0, 0, 0) ===
                new Date(date.date).setHours(0, 0, 0, 0)
              "
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  :color="date.disabled ? '#fc010e' : '#13e46d'"
                  style="margin-right: 8px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
              </template>
              <span>
                {{ dailyMenuOrderingTimeInfo }}
              </span></v-tooltip
            >
            <v-tooltip v-else-if="!date.disabled" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="#ff9f1a"
                  style="margin-right: 8px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
              </template>
              <span>
                {{ $t("acceptingPreOrders") }}
              </span></v-tooltip
            >
            {{ getWeekdayName((new Date(date.date).getDay() || 7) - 1) }}
            ({{ new Date(date.date).toLocaleDateString().replace(/ /g, "") }})
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="hello">
          <v-expansion-panels
            accordion
            :class="$vuetify.breakpoint.lgAndUp ? 'justify-start' : null"
          >
            <v-expansion-panel
              v-for="(dish, i) in date.items"
              :key="i"
              active-class="active"
              :disabled="
                date.disabled ||
                (!dish.allowedDeliveryMethods.takeaway &&
                  !dish.allowedDeliveryMethods.delivery &&
                  !dish.allowedDeliveryMethods.inplace) ||
                !isSelectedDeliveryMethodAllowed(dish)
              "
              :readonly="dish.options.length === 0"
              :style="
                $vuetify.breakpoint.lgAndUp
                  ? 'max-width: 50%; border-color: transparent'
                  : null
              "
            >
              <v-expansion-panel-header hide-actions class="py-0">
                <v-col
                  v-if="dish.imageUrl"
                  cols="1"
                  sm="3"
                  lg="3"
                  class="hidden-xs-only image-thumbnail"
                >
                  <v-img
                    class="mr-auto"
                    style="border-radius: 8px"
                    height="125"
                    :contain="imageContain === 'true'"
                    :src="dish.imageUrl"
                  ></v-img>
                </v-col>
                <v-col cols="12" :sm="dish.imageUrl ? 9 : 12">
                  <v-card-title style="padding-left: 0px; padding-top: 0px"
                    ><span
                      v-if="
                        order.findIndex((item) => item._id === dish._id) > -1
                      "
                      class="primary--text"
                      style="margin-right: 8px"
                      >{{
                        order
                          .filter((item) => item._id === dish._id)
                          .reduce(
                            (accumulator, dish) => accumulator + dish.quantity,
                            0
                          )
                      }}x</span
                    >{{ dish.name }}</v-card-title
                  >
                  <v-card-subtitle style="padding-left: 0px">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{
                          dish.description
                        }}</v-list-item-subtitle>
                      </template>
                      <span>{{ dish.description }}</span>
                    </v-tooltip>

                    <v-card-text
                      style="padding: 0px"
                      v-if="dish.allergens && dish.allergens.length > 0"
                    >
                      {{ $t("allergens") }}: {{ dish.allergens.join(", ") }}
                    </v-card-text>
                  </v-card-subtitle>
                  <v-card-text style="padding-left: 0px; padding-bottom: 0px">
                    <span class="font-weight-bold" style="padding-bottom: 0px"
                      >{{ calculateDishPrice(dish).toFixed(2)
                      }}{{ currencySymbol }}</span
                    >&#160;/{{ dish.amount }}
                    <v-btn
                      v-if="mandatoryOptionsProvided(dish)"
                      text
                      rounded
                      color="primary"
                      small
                      :disabled="
                        (!isOpen && !allowPreorderingWhenClosed) ||
                        date.disabled ||
                        (!dish.allowedDeliveryMethods.takeaway &&
                          !dish.allowedDeliveryMethods.delivery &&
                          !dish.allowedDeliveryMethods.inplace) ||
                        !mandatoryOptionsProvided(dish) ||
                        !isSelectedDeliveryMethodAllowed(dish)
                      "
                      @click.native.stop="
                        $emit('add-dish', {
                          dish,
                          options: selectedOptions[dish._id].filter(
                            (e) => dish.options.indexOf(e._id) !== -1
                          ),
                          price: calculateDishPrice(dish),
                          preorder: date.preordering,
                        });
                        resetOptions(dish);
                      "
                    >
                      {{ $t("add") }}
                    </v-btn>
                    <v-btn
                      v-else
                      text
                      rounded
                      color="primary"
                      small
                      :disabled="
                        (!isOpen && !allowPreorderingWhenClosed) ||
                        date.disabled ||
                        (!dish.allowedDeliveryMethods.takeaway &&
                          !dish.allowedDeliveryMethods.delivery &&
                          !dish.allowedDeliveryMethods.inplace) ||
                        !isSelectedDeliveryMethodAllowed(dish)
                      "
                    >
                      {{ $t("selectOptions") }}
                    </v-btn>

                    <div v-if="!date.disabled">
                      <AllowedDeliveryMethods
                        :allowedDeliveryMethods="dish.allowedDeliveryMethods"
                      />
                    </div>
                  </v-card-text>
                </v-col>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Options
                  v-if="dish.options.length !== 0"
                  :currencySymbol="currencySymbol"
                  :dish="dish"
                  :options="options"
                  :selectedOptions="selectedOptions[dish._id]"
                  :expandFirstOption="expandFirstOption"
                  class="options mb-2"
                  v-on:add-option="addOption($event, dish)"
                  v-on:remove-option="removeOption($event, dish)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Options from "./Options";
import AllowedDeliveryMethods from "./AllowedDeliveryMethods";

export default {
  name: "DailyMenu",
  components: {
    Options,
    AllowedDeliveryMethods,
  },
  props: [
    "isOpen",
    "allowPreorderingWhenClosed",
    "currencySymbol",
    "dailyMenuOrderingTime",
    "options",
    "data",
    "order",
    "customHeadings",
    "expandFirstOption",
    "imageContain",
  ],
  data: () => ({
    selectedOptions: {},
  }),
  created: function () {
    this.data.forEach((date) => {
      date.items.forEach((dish) => {
        this.$set(this.selectedOptions, dish._id, []);
      });
    });
  },
  computed: {
    dailyMenuOrderingTimeInfo: function () {
      const currentDate = new Date();

      let startDate = new Date(currentDate.getTime());
      startDate.setHours(this.dailyMenuOrderingTime.from.split(":")[0]);
      startDate.setMinutes(this.dailyMenuOrderingTime.from.split(":")[1]);

      let endDate = new Date(currentDate.getTime());
      endDate.setHours(this.dailyMenuOrderingTime.to.split(":")[0]);
      endDate.setMinutes(this.dailyMenuOrderingTime.to.split(":")[1]);

      if (startDate < currentDate && endDate > currentDate)
        return this.$t("ordersUntil", {
          time: this.dailyMenuOrderingTime.to,
        });
      else if (currentDate > endDate)
        return `${this.$t("noLongerPossibleToOrder")} (${this.$t(
          "ordersUntil",
          {
            time: this.dailyMenuOrderingTime.to,
          }
        ).toLowerCase()})`;
      else if (currentDate < startDate && endDate > startDate)
        return `${this.$t("notYetPossibleToOrder")} (${this.$t("ordersFrom", {
          time: this.dailyMenuOrderingTime.from,
        }).toLowerCase()})`;
      else
        return this.$t("ordersUntil", {
          time: this.dailyMenuOrderingTime.to,
        });
    },
  },
  methods: {
    getWeekdayName: function (day) {
      let weekday = new Array(7);
      weekday[0] = this.$t("monday");
      weekday[1] = this.$t("tuesday");
      weekday[2] = this.$t("wednesday");
      weekday[3] = this.$t("thursday");
      weekday[4] = this.$t("friday");
      weekday[5] = this.$t("saturday");
      weekday[6] = this.$t("sunday");

      return weekday[day];
    },
    addOption: function (event, dish) {
      const option = event.option;
      const value = event.value;
      const index = this.selectedOptions[dish._id].findIndex(
        (e) => e._id === option._id
      );
      if (index === -1) {
        this.selectedOptions[dish._id].push({
          _id: option._id,
          values: [
            {
              _id: value._id,
              name: value.name,
              price: value.price,
              quantity: 1,
            },
          ],
        });
      } else {
        const index2 = this.selectedOptions[dish._id][index].values.findIndex(
          (e) => e._id === value._id
        );
        if (index2 === -1) {
          this.selectedOptions[dish._id][index].values.push({
            _id: value._id,
            name: value.name,
            price: value.price,
            quantity: 1,
          });
        } else {
          this.selectedOptions[dish._id][index].values[index2].quantity++;
        }
      }
    },
    removeOption: function (event, dish) {
      const option = event.option;
      const value = event.value;
      const index = this.selectedOptions[dish._id].findIndex(
        (e) => e._id === option._id
      );
      if (index !== -1) {
        const index2 = this.selectedOptions[dish._id][index].values.findIndex(
          (e) => e._id === value._id
        );
        if (index2 !== -1) {
          const quantity =
            this.selectedOptions[dish._id][index].values[index2].quantity;
          if (quantity === 1)
            this.selectedOptions[dish._id][index].values.splice(index2, 1);
          else this.selectedOptions[dish._id][index].values[index2].quantity--;
        }
      }
    },
    mandatoryOptionsProvided: function (dish) {
      try {
        const options = this.options
          .filter((e) => dish.options.indexOf(e._id) !== -1)
          .filter((e) => e.minimumSelections >= 1);

        for (let i = 0; i < options.length; i++) {
          const index = this.selectedOptions[dish._id].findIndex(
            (e) => e._id === options[i]._id
          );

          if (
            !(
              this.selectedOptions[dish._id][index].values.length >=
              options[i].minimumSelections
            )
          )
            return false;
        }
        return true;
      } catch {
        return false;
      }
    },
    calculateDishPrice: function (dish) {
      let price = dish.price;
      try {
        const options = this.selectedOptions[dish._id].filter(
          (e) => dish.options.indexOf(e._id) !== -1
        );

        options.forEach((option) => {
          let optionsTotal = 0;
          option.values.forEach((value) => {
            optionsTotal += value.quantity * value.price;
          });
          price += optionsTotal;
        });

        return price;
      } catch (error) {
        return 0;
      }
    },
    resetOptions: function (dish) {
      this.$set(this.selectedOptions, dish._id, []);
    },
    isSelectedDeliveryMethodAllowed: function (dish) {
      const params = new URLSearchParams(window.location.search);
      const deliveryMethod = params.get("deliveryMethod");

      if (
        deliveryMethod === "takeaway" ||
        deliveryMethod === "delivery" ||
        deliveryMethod === "inplace"
      )
        return dish.allowedDeliveryMethods[deliveryMethod];

      return true;
    },
  },
};
</script>

<style>
.v-card__title {
  word-break: unset !important;
}

[piknik] .image-thumbnail {
  padding-right: 12px !important;
}

.v-expansion-panel:before {
  box-shadow: none !important;
}

[piknik] .full-description {
  display: none;
}

.dailyMenu .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}

.dailyMenu .v-expansion-panel:last-child .col {
  padding-bottom: 12px !important;
}

.dailyMenu
  .v-expansion-panels
  .v-expansion-panel
  .v-expansion-panel:first-child
  .col-sm-9,
.dailyMenu
  .v-expansion-panels
  .v-expansion-panel
  .v-expansion-panel:first-child
  .col-sm-12 {
  padding-top: 0 !important;
}

.dailyMenu .v-card__subtitle {
  padding-bottom: 8px !important;
}

.dailyMenu .v-expansion-panel-content__wrap {
  padding-bottom: 4px !important;
}

.dailyMenu
  .v-expansion-panels
  .v-expansion-panel
  .v-expansion-panel
  .image-thumbnail {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.dailyMenu
  .v-expansion-panels
  .v-expansion-panel
  .v-expansion-panel:first-child
  .image-thumbnail {
  padding-top: 0 !important;
}

@media (min-width: 1264px) {
  [piknik]
    .dailyMenu
    .v-expansion-panel
    .v-expansion-panel:not(:first-child)::after {
    border-top: none;
  }

  [piknik] .dailyMenu .options .v-expansion-panel:not(:first-child)::after {
    border-top: thin solid;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .dailyMenu
    .v-expansion-panels
    .v-expansion-panel
    .v-expansion-panel:nth-child(2)
    .col-sm-9,
  .dailyMenu
    .v-expansion-panels
    .v-expansion-panel
    .v-expansion-panel:nth-child(2)
    .col-sm-12 {
    padding-top: 0 !important;
  }

  .dailyMenu
    .v-expansion-panels
    .v-expansion-panel
    .v-expansion-panel:nth-child(2)
    .image-thumbnail {
    padding-top: 0 !important;
  }
}
</style>