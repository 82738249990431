import Vue from 'vue'
import VueI18n from 'vue-i18n';
import vueCustomElement from 'vue-custom-element'

import vuetify from './plugins/vuetify';

import App from './App.vue'

Vue.use(vueCustomElement)
Vue.use(VueI18n);

const loadLocaleMessages = () => {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const i18n = new VueI18n({
    locale: navigator.language.split("-")[0],
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
});

App.vuetify = vuetify
App.i18n = i18n

Vue.customElement('piknik-embeddable', App)